import { createTheme, Title } from "@mantine/core";
import { root } from "./title.module.css";

const primary = {
  s50: "#FFF0E3",
  s100: "#FFE1CC",
  s200: "#FFC29B",
  s300: "#FFA064",
  s400: "#FE8337",
  s500: "#FE701A",
  s600: "#FF690C",
  s700: "#E45600",
  s800: "#CB4B00",
  s900: "#B13E00",
};

export const theme = createTheme({
  fontFamily: "Inter, sans-serif",
  headings: {
    fontFamily: "Space Grotesk, sans-serif",
  },
  scale: 1,
  primaryColor: "orange",
  primaryShade: { light: 6, dark: 8 },
  defaultRadius: "lg",
  colors: {
    orange: [
      primary.s50,
      primary.s100,
      primary.s200,
      primary.s300,
      primary.s400,
      primary.s500,
      primary.s600,
      primary.s700,
      primary.s800,
      primary.s900,
    ],
  },
  components: {
    Title: Title.extend({
      classNames: { root },
      defaultProps: { variant: "narrow" },
    }),
  },
  // ... other theme override properties
});
