// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import React from "react";
import "@mantine/core/styles.css";

import { MantineProvider } from "@mantine/core";
import { theme } from "./src/theme";
import "@fontsource/pacifico";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/space-grotesk/300.css";
import "@fontsource/space-grotesk/400.css";
import "@fontsource/space-grotesk/500.css";
import "@fontsource/space-grotesk/600.css";
import "@fontsource/space-grotesk/700.css";

export const wrapPageElement = ({ element }) => {
  return <MantineProvider theme={theme}>{element}</MantineProvider>;
};
